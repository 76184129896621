import React from 'react';
import { View, StyleSheet } from 'react-native';

export default function IconView({ children, color }) {
    const background = color == undefined? {} : {backgroundColor: color}
    return (
        <View style={[styles.container, background]}>
            {children}
        </View>

    );
}

const styles = StyleSheet.create({
    container: {
        width: 150,
        height: 150,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: -125,
        marginBottom: 40,
        borderRadius: 100,
    },
})