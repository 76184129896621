import React, { useState, useEffect } from 'react';
import { View, FlatList, Text, Pressable, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import api from '../api/client';

export default function UserList({ groupID, selected, onSelect }) {

    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, [groupID]);

    const fetchData = async () => {
        const { data } = await api.get('/User/Group/' + groupID);
        setData(data);
    }

    const renderItem = ({item}) => {
        const { firstName, email } = item;
        return (
            <View style={[styles.card, {backgroundColor: selected.includes(email)? 'pink':'white'}]}>
                <View style={{width: 300}}>
                    <Text style={styles.email}>{ email }</Text>
                </View>
                <Pressable style={styles.btn} onPress={() => onSelect(email, firstName)}>
                    <MaterialCommunityIcons name="send" color="black" size={20} />
                </Pressable>
            </View>
        );
    };

    return (
        <FlatList
            data={data}
            numColumns={1}
            keyExtractor={(item, index) => index}
            renderItem={renderItem}
        ></FlatList>
    );
}

const styles = StyleSheet.create({
    card: {
        width: '100%',
        padding: 5,
        margin: 3,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderBottomColor: 'black'
    },
    chinese: {
        fontFamily: 'STZhongsong',
        fontSize: 20,
    },
    btn: {
        backgroundColor: '#A3E4D7',
        width: 50,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
    },
})