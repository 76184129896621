import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, StyleSheet } from 'react-native';
import api from '../api/client';

export default function Specification({ productID}) {

    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Product/Spec/' + productID);
        setData(data);
    }
    
    const renderItem = ({item}) => {
        return <View style={styles.row}>
            <View style={[styles.column, styles.left]}>
                <Text style={styles.text}>{item.name}</Text>
            </View>
            <View style={[styles.column, styles.right]}>
                <Text style={styles.text}>{item.value}</Text>
            </View>      
        </View>
    }

    return (
        <View style={styles.container}>
            <FlatList
                data={data}
                keyExtractor={item => item.vid}
                renderItem={renderItem}
            ></FlatList>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'column',
        backgroundColor: 'white',
        borderTopColor: 'lightgray',
        borderTopWidth: 1,
        alignSelf: 'center'
    },
    row: {
        flexDirection: 'row',
        borderColor: 'lightgray',
        borderBottomWidth: 1
    },
    column: {
        alignItems: 'flex-start',
        paddingHorizontal: 25,
        paddingVertical: 5,
        borderRightWidth: 1,
        borderRightColor: 'lightgray',
    },
    left: {
        flex: 1,
        borderLeftWidth: 1,
        borderLeftColor: 'lightgray',
    },
    right: {
        flex: 2,
    },
    text: {
        fontFamily: 'Calibri',
        fontSize: 18
    }
})
