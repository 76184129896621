import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import IdiomMenu from '../components/IdiomMenu';
import IdiomForm from '../components/IdiomForm';

const Stack = createNativeStackNavigator();

const IdiomNavigator = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name="IdiomMenu" component={IdiomMenu} options={{ headerShown: false }} />
            <Stack.Screen name="IdiomForm" component={IdiomForm} options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default IdiomNavigator;