import React, { useState, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import Navigator from './app/navigation/NavigationStack';
import { AuthContext } from './app/components/Context';
import storage from './app/api/storage';
import Login from './app/components/Login';

export default function App() {
    const [user, setUser] = useState();

    const restoreUser = async () => {
        const user = await storage.getUser();
        if (user) setUser();
    }

    useEffect(() => {
        restoreUser();
    }, []);

    return (
        <AuthContext.Provider value={{user, setUser}}>
            {!user? <Login />:
            <NavigationContainer>
                <Navigator />
            </NavigationContainer>}
        </AuthContext.Provider>
    );
}