import React, { useState, useEffect } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { Form, FormField, SubmitButton, ErrorMessage } from './form';
import IconView from './IconView';

import * as Yup from 'yup';
import api from '../api/client';
import server from '../config/server';
import useAuth from '../hooks/useAuth';

const schema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
    password: Yup.string().required().min(6).max(20).label("Password")
});

export default function Login({ navigation, route }) {

    const domain = server["domain"];

    const { user, logIn } = useAuth();
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const [ip, setIP] = useState(null);

    useEffect(() => {
        getIP();
        if (user)
            log();
    }, [user]);

    const getIP = async () => {
        const res = await api.get("https://api.ipify.org/?format=json");
        const ip = res.data.ip;
        setIP(ip);
    }

    const log = async () => {
        await api.put("/User/Log/" + user.personID + "/" + ip);
    }

    const login = async (email, password) => {
      //  const ip = getIP();

        const { data } = await api.get("/User/Auth/" + email + "/" + password);

        console.log(JSON.stringify(data));

        if (data == null) {
            setError(true);
            setMessage('Invalid email or password.');
            return;
        }

        logIn(data);
        
        return navigation.navigate('Email');
    }

    return (
        <View style={styles.container}>
            <View style={styles.form}>
                <IconView>
                    <Image style={styles.userIcon} source={{uri: domain + '/images/common/verified-user.png'}} />
                </IconView>
                <Form
                    initialValues={{email: '', password: ''}}
                    onSubmit={({email, password}) => login(email, password)}
                    validationSchema={schema}
                >
                    {error && <View style={{marginBottom: 10}}>
                                    <ErrorMessage text={message} />
                                </View>}
                    <FormField
                        name="email"
                        icon="email"
                        placeholder="Email"
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType="email-address"
                        textContentType="emailAddress"
                    />
                    <FormField 
                        name="password"
                        icon="lock"
                        placeholder="Password"
                        autoCapitalize="none"
                        autoCorrect={false}
                        textContentType="password"
                        secureTextEntry={true}
                    />
                    <SubmitButton text="Login" />
                </Form>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center'
    },
    form: {
        width: '50%',
        height: 'auto',
        padding: 50,
        borderRadius: 30,
        alignItems: 'center',
        backgroundColor: '#D1F2EB',
    },
    userIcon: {
        width: 146,
        height: 146,  
    },
})