import React from 'react';
import { Text, StyleSheet } from 'react-native';

export default function ErrorMessage({ text }) {
    return (
        <Text style={styles.error}>{text}</Text>
    );
}

const styles = StyleSheet.create({
    error: {
        fontFamily: 'Candara',
        fontSize: 20,
        color: '#FF004C'
    },
})