import React, { useState, useEffect } from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import Specification from './Specification';

import api from '../api/client';

export default function ProductDesc({ productID, attriGroupID }) {

    const [desc, setDesc] = useState('');
    const [showSpec, setShowSpec] = useState(attriGroupID == 1);
 
    useEffect(() => {
        fetchDesc();
    }, []);

    const fetchDesc = async () => {
        const { data } = await api.get('/Product/Desc/' + productID);
        setDesc(data);
    }

    const selectedStyle = {
        borderBottomWidth: 2,
        borderBottomColor: 'orange'
    }

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <Pressable onPress={() => setShowSpec(true)}>
                    <View style={[styles.button, showSpec? selectedStyle : null]}>
                        <Text style={styles.text}>Specification</Text>
                    </View>                        
                </Pressable>
                <Pressable onPress={() => setShowSpec(false)}>
                    <View style={[styles.button, !showSpec? selectedStyle : null]}>
                        <Text style={styles.text}>Description</Text>
                    </View>
                </Pressable>
            </View>

            {showSpec? <Specification productID={productID} /> : <Text style={styles.desc}>{desc}</Text>}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginVertical: 20
    },
    button: {
        marginHorizontal: 30,
        borderBottomWidth: 1,
        borderBottomColor: 'black'
    },
    text: {
        ontFamily: 'Candara',
        fontSize: 20,
        margin: 10
    },
    desc: {
        fontFamily: 'Calibri',
        fontSize: 18
    }
})
