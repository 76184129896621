import { useContext } from 'react';
import { AuthContext } from '../components/Context';
import storage from '../api/storage';

const useAuth = () => {
    const { user, setUser } = useContext(AuthContext);

    const logIn = (authToken) => {
     //   const user = jwtDecode(authToken);
        setUser(authToken);
        storage.storeToken(authToken);
    }

    const logOut = () => {
        setUser(null);
        storage.removeToken();
    }

    return { user, logIn, logOut };
}

export default useAuth;