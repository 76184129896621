import React, { useState, useEffect } from 'react';
import { View, Text, Modal, StyleSheet } from 'react-native';

export default function Feedback({ text, visible }) {
    const [show, setShow] =  useState(visible);

    const fadeOut = () => {
        setTimeout(() => {
            setShow(false);
        }, 2000);
    }

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={show}
            onShow={fadeOut}
            presentationStyle="formSheet"
        >
            <View style={styles.m1}>
                <View style={styles.m2}>
                    <Text style={styles.text}>{text}</Text>
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    m1: {
        flex: 1,
        width: '80%',
        justifyContent: "center",
        alignSelf: 'center',
        alignItems: "center",
        backgroundColor: 'lightgreen'
    },
    m2: {
        width: '80%',
        backgroundColor: 'white',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 40,
        paddingVertical: 30,
        margin: 20,
        borderRadius: 10,
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.4)',
    },
    text: {
        fontFamily: 'Calibri',
        fontSize: 18
    }
})