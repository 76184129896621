import React from 'react';
import { View, Text, Image, Pressable, StyleSheet } from 'react-native';
import server from '../config/server';

export default function BookCard({ data, onPress }) {

    const { bid, isDone, chinese } = data;
    const domain = server["domain"];
    const bgColor = isDone? {backgroundColor: '#4CBB17'} : {backgroundColor: '#00BFFF'};

    return (
        <Pressable onPress={onPress}>
            <View style={styles.card}>
                <Image style={styles.image} source={{uri: domain + '/images/book/' + bid + '.jpg'}} />
                <View style={[styles.textV, bgColor]}>
                    <Text style={styles.chinese}>{ chinese }</Text>
                </View>
            </View>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    card: {
        width: 200,
        height: 250,
        margin: 20,
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        boxShadow: '0 10px 20px rgb(0 0 0 / 0.3)',
    },
    image: {
        width: 200,
        height: 200,
        resizeMode: 'contain',
    },
    textV: {
        width: '100%',
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#58D68D'
    },
    chinese: {
        fontFamily: 'STZhongsong',
        fontSize: 22,
        color: 'black',
    },
})