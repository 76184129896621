import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import ChatRequest from './ChatRequest';
import ChatForm from './ChatForm';
import NoRight from './NoRight';

import useAuth from '../hooks/useAuth';

export default function ChatManager({  }) {
    const { user:admin } = useAuth();
    const roles = admin.roles;

    if (!roles.includes('Customer Service'))
        return <NoRight />;

    const [chatID, setChatID] = useState(0);

    return (
        <View style={styles.container}>
            <View style={{width: '20%'}}>
                <ChatRequest onSelect={chatID => setChatID(chatID)} />
            </View>
            <View style={{width: '80%'}}>
                <ChatForm chatID={chatID} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
})