import React from 'react';
import { Text, StyleSheet } from 'react-native';

export default function Message({ text }) {
    return (
        <Text style={styles.text}>{text}</Text>
    );
}

const styles = StyleSheet.create({
    text: {
        fontFamily: 'Candara',
        fontSize: 20,
        color: '#2ECC71'
    },
})