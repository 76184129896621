import React from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';

export default function UserGroup({ groupID, onSelect }) {
    const colors = ['#FE9600', '#A020F0', '#00BFFF', '#4CBB17', 'lightpink', '#FF004C'];
    const defCol = 'white';

    return (
        <View style={styles.container}>
            <View style={{width: 100}}>
                <Text style={styles.text}>User Group</Text>
            </View>
            <View style={{width:100}}>
                <Pressable onPress={() => onSelect(0)}
                           style={[styles.btn, {borderColor: colors[0], backgroundColor: groupID == 0? colors[0]: defCol}]}>
                    <Text style={styles.text}>Test Users</Text>
                </Pressable>
            </View>
            <View style={{width:90}}>
                <Pressable onPress={() => onSelect(1)}
                           style={[styles.btn, {borderColor: colors[1], backgroundColor: groupID == 1? colors[1]: defCol}]}>
                    <Text style={styles.text}>All</Text>
                </Pressable>
            </View>
            <View style={{width:160}}>
                <Pressable onPress={() => onSelect(2)}
                           style={[styles.btn, {borderColor: colors[2], backgroundColor: groupID == 2? colors[2]: defCol}]}>
                    <Text style={styles.text}>Never Logged In</Text>
                </Pressable>
            </View>
            <View style={{width:200}}>
                <Pressable onPress={() => onSelect(3)}
                           style={[styles.btn, {borderColor: colors[3], backgroundColor: groupID == 3? colors[3]: defCol}]}>
                    <Text style={styles.text}>Not Logged In 30 Days</Text>
                </Pressable>
            </View>
            <View style={{width:180}}>
                <Pressable onPress={() => onSelect(4)}
                           style={[styles.btn, {borderColor: colors[4], backgroundColor: groupID == 4? colors[4]: defCol}]}>
                    <Text style={styles.text}>Logged In Never VIP</Text>
                </Pressable>
            </View>
            <View style={{width:180}}>
                <Pressable onPress={() => onSelect(5)}
                           style={[styles.btn, {borderColor: colors[5], backgroundColor: groupID == 5? colors[5]: defCol}]}>
                    <Text style={styles.text}>VIP Expired 30 Days</Text>
                </Pressable>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    btn: {
        borderWidth: 2,
        height: 35,
        marginLeft: 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    text: {
        fontFamily: 'Calibri',
        fontSize: 18,
    }
})