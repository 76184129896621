import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useFormikContext } from 'formik';
import TextLine from './TextLine';

export default function FormLine({name, ...otherProps}) {
    const { handleChange, errors, touched, setFieldTouched } = useFormikContext();

    return (
        <View style={styles.container}>
            <TextLine
                style={styles.box}
                {...otherProps} 
                onChangeText={handleChange(name)}
                onBlur={() => setFieldTouched(name)}
            />
            {touched[name] && <Text style={styles.error}>{errors[name]}</Text>}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        marginBottom: 20,
    },
    box: {
        width: '100%',
        height: 50,
        fontFamily: 'Candara',
        fontSize: 18,
    },
    error: {
        fontFamily: 'Candara',
        fontSize: 18,
        color: '#FF004C',
        marginTop: 5,
        alignSelf: 'center'
    }
})