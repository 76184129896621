import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import useAuth from '../hooks/useAuth';

export default function Chat({ data }) {
    const { user } = useAuth();
    const { id, personID, adminID, text, time } = data;
   
    const myStyle = {alignSelf: 'flex-end', backgroundColor: '#A2D9CE', color: 'white', borderColor: '#D0ECE7'};
    const yourStyle = {alignSelf: 'flex-start', backgroundColor: 'white', color: 'black', borderColor: '#D0ECE7'}

    return (
        // <View style={[styles.p, personID == user.personID? myStyle: yourStyle]}>
        <View style={[styles.p, adminID > 0? myStyle: yourStyle]}>
            <Text style={styles.text}>{text}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    p: {
        backgroundColor: 'white',

        borderWidth: 1,
        borderRadius: 20,

        marginLeft: 20,
        marginRight: 20,
        marginBottom: 10,

        paddingVertical: 10,
        paddingHorizontal: 20,

        position: 'relative',
        justifyContent: 'center'
    },
    text: {
        color: 'black',
        fontFamily: 'Candara',
        fontSize: 18
    }
})