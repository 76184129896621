import React, { useState } from 'react';
import { View, TextInput, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export default function TextBox({icon, ...otherProps}) {
    const [style, setStyle] = useState(null);

    const onFocus = () => {
        const style = {backgroundColor: 'green', outline: 'none'};
        setStyle(style);
    }

    return (
        <View style={styles.container}>
            {icon && <MaterialCommunityIcons name={icon} size={30} color={'black'} style={styles.icon} />}
            <TextInput style={[styles.input, style]}
            {...otherProps} onFocus={() => onFocus()} autoCompleteType="off" />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#9af2fe',
        borderColor: 'black',
        borderWidth: 5,
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        marginHorizontal: 15,
        alignSelf: 'center'
    },
    input: {
        flex: 1,
        // WebkitBoxShadow: '0 0 0 1000px white inset',
    },
})