import React, { useState } from 'react';
import { View, TextInput, StyleSheet } from 'react-native';

export default function TextLine({...otherProps}) {
    const [style, setStyle] = useState(null);

    const onFocus = () => {
        const style = {backgroundColor: 'green', outline: 'none'};
        setStyle(style);
    }

    return (
        <View style={styles.container}>
            <TextInput style={[styles.input, style]}
            {...otherProps} onFocus={() => onFocus()} autoCompleteType="off" />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#D0ECE7',
        borderColor: 'black',
        borderBottomWidth: 2,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 15
    },
    input: {
        flex: 1,
    },
})