import React, { useState, useEffect } from 'react';
import { View, Text, Button, FlatList, StyleSheet } from 'react-native';
import Popup from './Popup';
import api from '../api/client';
import TempEditor from './TempEditor';

export default function MemberOrder() {

    const [data, setData] = useState([]);
    const [showMail, setShowMail] = useState(false);

    const tempID = 11;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Order/');
        setData(data);
    }

    const sendMail = async (email, name) => {
        await api.put('/Email/' + tempID + '/' + email + '/' + name);
    }

    const renderHeader = () => (
        <View style={[styles.row, {backgroundColor: '#D6EAF8'}]}>
            <View style={styles.v0}>
                <Text style={styles.text}>Name</Text>
            </View>
            <View style={styles.v1}>
                <Text style={styles.text}>Created Date</Text>
            </View>
            <View style={styles.v2}>
                <Text style={styles.text}>Paid Date</Text>
            </View>
            <View style={styles.v3}>
                <Text style={styles.text}>Package Type</Text>
            </View> 
            <View style={styles.v4}>
                <Text style={styles.text}>Amount</Text>
            </View>
            <View style={styles.v5}>
                <Text style={styles.text}>Payment<br />Method</Text>
            </View>
            <View style={styles.v6}>
                <Text style={styles.text}>Paypal Reference ID</Text>
            </View>
            <View style={styles.v7}>
                <Text style={styles.text}></Text>
            </View>
            <View style={styles.v8}>
                <Text style={styles.text}></Text>
            </View>
        </View>
    );

    const renderItem = ({item}) => {
        const { id, name, email, membership, amount, paymentMethod, paypalID, createdDate, paidDate } = item;
        let paid = paidDate.substring(0, 10);
        if (paid.startsWith('0001-')) paid = null;

        return (
        <View style={[styles.row, { backgroundColor: paid == null? 'pink': 'white'}]}>
            <View style={styles.v0}>
                <Text>{name}</Text>
            </View>
            <View style={styles.v1}>
                <Text>{createdDate.substring(0, 10)}</Text>
            </View>
            <View style={styles.v2}>
                {paid && <Text>{paidDate.substring(0, 10)}</Text>}
            </View>
            <View style={styles.v3}>
                <Text>{membership}</Text>
            </View>      
            <View style={styles.v4}>
                <Text>${amount}</Text>
            </View>
            <View style={styles.v5}>
                <Text>{paymentMethod}</Text>
            </View>
            <View style={styles.v6}>
                <Text>{paypalID}</Text>
            </View>
            <View style={styles.v7}>
                {!paid && <Button title="Show" color="#4CBB17" onPress={() => setShowMail(true)} />}
            </View>
            <View style={styles.v8}>
                {!paid && <Button title="Send Mail" color="dodgerblue" onPress={() => sendMail(email, name)} />}
            </View>
        </View>
        );
    }

    return (
        <View style={styles.container}>
            {data.length > 0 && renderHeader()}
            <FlatList
                data={data}
                numColumns={1}
                keyExtractor={(item, index) => index}
                renderItem={renderItem}
                style={styles.list}
            ></FlatList>
            <Popup show={showMail} onClose={() => setShowMail(false)}>
                <TempEditor tempID={tempID} />
            </Popup>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    row: {
        width: '100%',
        height: 50,
        backgroundColor: 'white',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: 'lightgray',
    },
    v0: {
        width:'10%',
    },
    v1: {
        width:'10%',
        alignItems: 'center',
    },
    v2: {
        width:'10%',
        alignItems: 'center',
    },
    v3: {
        width:'15%',
    },
    v4: {
        width:'5%',
    },
    v5: {
        width:'10%',
    },
    v6: {
        width:'20%',
    },
    v7: {
        width:'8%',
        paddingRight: 20
    },
    v8: {
        width:'8%',
    },
    text: {
        fontFamily: 'Candara',
        fontSize: 16
    },
})