import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Pressable, StyleSheet } from 'react-native';

import api from '../api/client';

export default function SkinCareSpec({ productID }) {

    const [desc, setDesc] = useState('');
    const [content, setContent] = useState('');

    const [ingredients, setIngredients] = useState(null);     
    const [result, setResult] = useState(null);               
    const [instructions, setInstructions] = useState(null);

    const [selectID, setSelectID] = useState(1);
 
    useEffect(() => {
        fetchDesc();
        fetchMore();
    }, []);

    const fetchDesc = async () => {
        const { data } = await api.get('/Product/Desc/' + productID);
        setDesc(data);
        setContent(data);
    }

    const fetchMore = async () => {
        const { data } = await api.get('/Product/More/' + productID);
        if (data == null)
            return;

        setIngredients(data.item1);
        setResult(data.item2);
        setInstructions(data.item3);
    }

    const handleSelect = (id) => {
        setSelectID(id);
        switch (id) {
            case 1:
                setContent(desc); break;
            case 2:
                setContent(ingredients); break;
            case 3:
                setContent(result); break;
            case 4:
                setContent(instructions); break;
        }
    }

    const selectedStyle = {
        borderBottomWidth: 2,
        borderBottomColor: 'orange'
    }

    const renderContent = () => {
        if (content == null) return;
        
        let index = content.indexOf(';');

        if (selectID == 1 || index < 1)
            return <Text style={styles.text}>{content}</Text>;

        const arr = content.split(';');
        return (
            <FlatList
                data={arr}
                keyExtractor={(item, index) => index}
                renderItem={renderListItem}
                numColumns={1}
            ></FlatList>
        );
    }

    const renderListItem = ({ item }) => {
        return (
            <View style={{paddingVertical: 5}}>
                <Text style={styles.text}>• {item}</Text>
            </View>);
    }

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <Pressable onPress={() => handleSelect(1)}>
                    <View style={[styles.pressBtn, selectID == 1? selectedStyle : null]}>
                        <Text style={styles.linkBtn}>Description</Text>
                    </View>
                </Pressable>
                <Pressable onPress={() => handleSelect(2)}>
                    <View style={[styles.pressBtn, selectID == 2? selectedStyle : null]}>
                        <Text style={styles.linkBtn}>Ingredients</Text>
                    </View>
                </Pressable>
                <Pressable onPress={() => handleSelect(3)}>
                    <View style={[styles.pressBtn, selectID == 3? selectedStyle : null]}>
                        <Text style={styles.linkBtn}>Result</Text>
                    </View>
                </Pressable>
                <Pressable onPress={() => handleSelect(4)}>
                    <View style={[styles.pressBtn, selectID == 4? selectedStyle : null]}>
                        <Text style={styles.linkBtn}>Instructions</Text>
                    </View>
                </Pressable>
            </View>

            <View style={styles.row}>
            {renderContent()}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        backgroundColor: 'white',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 20
    },
    pressBtn: {
        marginHorizontal: 30,
        borderBottomWidth: 1,
        borderBottomColor: 'black'
    },
    linkBtn: {
        ontFamily: 'Candara',
        fontSize: 20,
        margin: 10
    },
    text: {
        fontFamily: 'Candara',
        fontSize: 18
    },
})
