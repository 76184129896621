import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

export default function NoRight() {
    return (
        <View style={styles.container}>
            <Text style={styles.text}>You have no right to view this page.</Text>
        </View>);
    }

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'aliceblue'
    },
    text: {
        fontFamily: 'Calibri',
        fontSize: 24
    }
})