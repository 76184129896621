import React, { useState, useEffect } from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import BookCard from './BookCard';
import NoRight from './NoRight';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function BookMenu({ navigation }) {
    const { user:admin } = useAuth();
    const roles = admin.roles;

    if (!roles.includes('Super User') && !roles.includes('Editor'))
        return <NoRight />;

    const [data, setData] = useState(null);

    const newBook = { bid: '0', chinese: 'Create New Book' };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Book/');
        setData(data);
    }

    const handleSelect = async (bID) => {
        return navigation.navigate("Book", { BID: bID });
    }

    return (
        <View style={styles.container}>
            <ScrollView>
                <View style={styles.list}>
                    <BookCard data={newBook} onPress={() => navigation.navigate("Book", { BID: 0 })} />
                    {data && data.map(item =>
                        <View key={item.bid}>
                            <BookCard data={item} onPress={() => handleSelect(item.bid)} />
                        </View>
                )}
                </View>
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EBF5FB',
    },
    list: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '90%',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        marginBottom: 30
    },
})