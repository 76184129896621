import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native';
import Pagination from './Pagination';
import MyButton from './form/MyButton';

import api from '../api/client';

export default function BookText({ route }) {

    const { BID } = route.params;

    const [pages, setPages] = useState([]); //page ids
    const [index, setIndex] = useState(0);
    const [pID, setPID] = useState(0);  //page data
    const [chinese, setChinese] = useState('');
    const [english, setEnglish] = useState('');

    useEffect(() => {
        fetchPages();
    }, [index]);

    const fetchPages = async() => {
        const { data } = await api.get('/Book/Pages/' + BID);
        data.push(0,0,0,0,0,0,0,0,0,0);
        setPages(data);
        fetchPage(data[index]);
    }

    const fetchPage = async(pID) => {
        if (pID > 0) {
            const { data } = await api.get('/Book/' + BID + '/' + pID);
            setPID(data.pid);
            setChinese(data.chinese);
            setEnglish(data.english);
        } else {
            clear();
        }
    }

    const savePage = async () => {
        let page = new FormData();
        page.append("PID", pID);
        page.append("BID", BID);
        page.append("Chinese", chinese);
        page.append("English", english.length == 0? "E" : english);

        await api.post("/Book/Text/", page);
        setIndex(index + 1);
    }

    const htmlEncoding = (text) => {
        let t = text.replace(/\'/g, "%27")
                    .replace(/\"/g, "%22")
                    .replace(/\?/g, "%3F");
        return t;
    }

    const clear = () => {
        setPID(0);
        setChinese('');
        setEnglish('');
    }

    return (
        <View style={styles.container}>
            {pages.length > 0 && <Pagination total={pages.length} pageSize={1} pageID={index+1} onChange={item => setIndex(item-1)} />}
            <View style={styles.main}>
                <View style={styles.left}>
                    <TextInput
                        value={chinese}
                        onChangeText={t => setChinese(t)}
                        maxLength={200}
                        editable={true}
                        multiline
                        numberOfLines={12}
                        style={[styles.box, {fontSize: 35}]}
                    />
                    <Text>{chinese.length}</Text>
                </View>
                <View style={styles.right}>
                    <TextInput
                        value={english}
                        onChangeText={t => setEnglish(t)}
                        maxLength={800}
                        editable={true}
                        multiline
                        numberOfLines={12}
                        style={[styles.box, {fontSize: 25}]}
                    />
                    <Text>{english.length}</Text>
                </View>
            </View>
            <View style={{marginTop: 10}}>
                <Text>PID: {pID}</Text>
                <MyButton text="Save" color="dodgerblue" width={400} onPress={() => savePage()} />
            </View> 
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#EBF5FB',
    },
    main: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    left: {
        width: '50%',
    },
    right: {
        width: '50%',
        marginLeft: 10
    },
    box: {
        width: '40vw',
        height: '70vh',
        padding: 10,
        fontFamily: 'STZhongsong',
        borderWidth: 2,
        borderRadius: 4,
        borderColor: 'dodgerblue'
    },
})