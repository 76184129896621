import React, { useState, useEffect } from 'react';
import { View, Image, Text, FlatList, Pressable, StyleSheet } from 'react-native';
import Price from './Price';
import NoRight from './NoRight';

import api from '../api/client';
import server from '../config/server';
import useAuth from '../hooks/useAuth';

export default function Shop({ navigation }) {
    const { user:admin } = useAuth();
    const roles = admin.roles;

    if (!roles.includes('Ecommerce Manager'))
        return <NoRight />;

    const [data, setData] = useState([]);
    const domain = server['domain'];

    const fetchData = async () => {
        const { data } = await api.get('/Product/');
        setData(data);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handlePress = async (pid) => {
        const personID = user == undefined? 0: admin.pid;
        await api.put('/Product/View/' + pid + '/' + personID);

        navigation.navigate("Product", { productID: pid });
    }

    const renderItem = ({ item }) => {
        const { pid, brand, productName, price } = item;
        return (
            <Pressable onPress={() => handlePress(pid) }>
                <View style={styles.card}>
                    <Image style={styles.image} source={{uri: domain + '/images/shop/' + item.pid + '/1.jpg'}} />
                    <View style={styles.vName}>
                        <Text style={[styles.pName, {marginRight: 4}]}>{ brand }</Text>
                        <Text style={styles.pName}>{ productName }</Text>
                    </View>
                    <Price price={price} size={16} />
                </View>
            </Pressable>
        );
    }

    return (
        <View style={styles.container}>
            <View style={{flex: 1}}>
                <FlatList
                    data={data}
                    keyExtractor={item => item.pid}
                    renderItem={renderItem}
                    numColumns={6}
                ></FlatList>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#EBF5FB',
    },
    card: {
        backgroundColor: 'white',
        width: '13vw',
        height: 'auto',
        padding: 15,
        margin: 5,
        // alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: 4
    },
    image: {
        width: '11vw',
        height: '11vw',
        resizeMode: 'contain',
        alignSelf: 'center'
    },
    vName: {
        marginTop: 10,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        height: '4vw'
    },
    pName: {
        fontFamily: 'Calibri',
        fontSize: 16,
        justifyContent: 'center'
    },
    price: {
        fontFamily: 'Verdana',
        fontSize: 16,
    },
})