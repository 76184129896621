import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Pressable, StyleSheet } from 'react-native';

import api from '../api/client';

export default function Template({ tempID, onSelect }) {
    const color = ['#A020F0', '#A020F0', '#00BFFF', '#00BFFF', '#4CBB17', '#4CBB17',
                   'lightpink', 'lightpink', '#FF004C', '#FF004C'];

    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Email/Template');
        setData(data);
    }

    const renderItem = ({item}) => {
        const { id, tempName } = item;
        return (
            <Pressable onPress={() => onSelect(id)}
                    style={[styles.btn, { borderColor: color[id - 1],
                                backgroundColor: tempID == id? color[tempID - 1]: 'white'}]}>
                <Text style={styles.text}>{tempName}</Text>
            </Pressable>
        );
    }

    return (
        <View style={styles.container}>
            <View style={{width: 100}}>
                <Text style={styles.text}>Templates</Text>
            </View>
            <FlatList
                data={data}
                keyExtractor={(item, index) => index}
                renderItem={renderItem}
                horizontal={true}
            ></FlatList>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    btn: {
        borderWidth: 2,
        padding: 5,
        height: 35,
        marginLeft: 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    text: {
        fontFamily: 'Calibri',
        fontSize: 18,
    },
})