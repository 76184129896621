import React from 'react';
import { View, StyleSheet } from 'react-native';
import { createNavigator, TabRouter, SafeAreaView } from 'react-navigation';

import SidebarTabs from './SidebarTabs';
import TopTabs from './TopTabs';
import Logo from '../components/Logo';

const SidebarTabsNavigator = ({ navigation, descriptors }) => {
  const { routes, index } = navigation.state;
  const descriptor = descriptors[routes[index].key];
  const ActiveScreen = descriptor.getComponent();

  return (
      <SafeAreaView style={styles.container}>
          <View style={styles.left}>
              <Logo color='white' />
              <SidebarTabs descriptors={descriptors} navigation={navigation} />
          </View>
          <View style={styles.right}>
            <View style={{minHeight: '98%'}}>
                <ActiveScreen navigation={descriptor.navigation} />
            </View>
        </View>
      </SafeAreaView>
  );
};

const createSidebarNavigator = (routeConfigMap, sidebarNavigatorConfig) => {
    const customTabRouter = TabRouter(routeConfigMap, sidebarNavigatorConfig);
    return createNavigator(SidebarTabsNavigator, customTabRouter, {});
};

export default createSidebarNavigator;

const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'row',
      backgroundColor: '#000000'
    },
    left: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: 5,
      width: '12%',
      zIndex: 10,
    },
    right: {
      flex: 1,
      flexDirection: 'column',
      backgroundColor: 'black',
      paddingHorizontal: 5,
      paddingTop: 0,
      paddingBottom: 5,
      justifyContent: 'center'
    },
    logo: {
      width: 120,
      height: 90,
      alignSelf: 'center'
    },
    name: {
      fontFamily: 'Candara',
      fontSize: 18,
      color: 'white',
      alignSelf: 'center'
    }
})