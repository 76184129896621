import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Users from '../components/Users';

const Stack = createNativeStackNavigator();

const UserNavigator = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name="Users" component={ Users } options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default UserNavigator;