import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useFormikContext } from 'formik';
import TextBox from './TextBox';

export default function FormField({name, ...otherProps}) {
    const { handleChange, errors, touched, setFieldTouched } = useFormikContext();

    return (
        <View style={styles.container}>
            <TextBox
                style={styles.box}
                {...otherProps} 
                onChangeText={handleChange(name)}
                onBlur={() => setFieldTouched(name)}
            />
            {touched[name] && <Text style={styles.error}>{errors[name]}</Text>}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        marginBottom: 20,
    },
    box: {
        width: '90%',
        height: 60,
        fontFamily: 'Candara',
        fontSize: 20,
        padding: 10,
    },
    error: {
        fontFamily: 'Candara',
        fontSize: 18,
        color: '#FF004C',
        marginTop: 5,
        alignSelf: 'center'
    },

    // input:-webkit-autofill,
    // input:-webkit-autofill:hover,
    // input:-webkit-autofill:focus {
    // -webkit-text-fill-color: white;
    // -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    // transition: background-color 5000s ease-in-out 0s;
    // }

})