import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Pressable, StyleSheet } from 'react-native';

import api from '../api/client';

export default function ProductOption({ productID, onSelect }) {

    const [data, setData] = useState([]);
    const [optionID, setOptionID] = useState(0);

    const activeStyle = {borderColor: 'black', borderWidth: 2};

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Product/Option/' + productID);
        setData(data);
        setOptionID(data[0].id);
        onSelect(data[0].id, data[0].price);
    }

    const renderItem = ({item}) => {
        const { id, optName, price } = item;
        return (
            <Pressable onPress={() => {onSelect(id, price); setOptionID(item.id)}}>
                <View style={[styles.box, item.id === optionID? activeStyle : null]}>
                    <Text>{optName}</Text>
                </View>
            </Pressable>       
        )
    }

    if (data.length < 1)
    return null;

    return (
        <View style={styles.container}>
            <Text style={{fontSize: 18, marginBottom: 5}}>Option</Text>
            <FlatList
                data={data}
                keyExtractor={item => item.id}
                renderItem={renderItem}
                horizontal={true}
            ></FlatList>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        marginVertical: 10
    },
    box: {
        borderWidth: 1,
        borderColor: 'gray',
        paddingHorizontal: 20,
        paddingVertical: 5,
        marginRight: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    text: {
        fontFamily: 'Candara',
        fontSize: 18
    },
    save: {
        fontFamily: 'Candara',
        fontSize: 18,
        color: 'red'
    }
})
