import React from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const SidebarTabs = ({ navigation }) => {
    const { routes, index } = navigation.state;

   // const filtered = routes.slice(0, 8);

    const renderItem = (route, tabIndex) => {
        const { routeName, params } = route;
        const { icon, tabName } = params || {};
        const activeStyle = tabIndex == index? {backgroundColor: '#A020F0', width: '100%', marginLeft: '5%'} : null;
        const c = tabIndex == index? 'white' : 'black';

        return (
          <Pressable
              style={[styles.tab, activeStyle]}
              onPress={() => { navigation.navigate(routeName)}}
              key={route.routeName}
          >
              <MaterialCommunityIcons name={icon} color={c} size={30} style={{marginLeft: 15}} />
              <Text style={[styles.text, {color: c}]}>{tabName}</Text>
          </Pressable>
        );
    };

    return (
        <View style={styles.container}>
            {routes.map((route, tabIndex) => renderItem(route, tabIndex))}
        </View>
    );
};

export default SidebarTabs;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 15,
    },
    tab: {
        flex: 1,
        width: '95%',
        flexDirection: 'row',
      //  backgroundColor: '#40E0D0',
        backgroundColor: 'dodgerblue',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderBottomColor: '606060',
        borderBottomWidth: 3,
        borderRadius: 10,
        marginBottom: 4
    },
    text: {
        fontFamily: 'Comic Sans MS',
        fontSize: 18,
        color: 'black',
        marginLeft: 10
    }
});