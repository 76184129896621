import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, ScrollView, Pressable, StyleSheet } from 'react-native';

import api from '../api/client';

export default function ChatRequest({ onSelect }) {

    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Chat/Request');
        setData(data);
    }

    const renderItem = ({item}) => {
        const { chatID, name, text } = item;
        return (
            <Pressable onPress={() => onSelect(chatID)}>
                <View style={styles.card}>
                    <Text style={styles.name}>{name}</Text>
                    <Text style={styles.text}>{text}</Text>
                </View>
            </Pressable>
        );
    }

    return (
        <ScrollView style={styles.container}>
            <FlatList
                data={data}
                numColumns={1}
                keyExtractor={item => item.chatID}
                renderItem={renderItem}
            ></FlatList>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#E8F6F3',
        borderRadius: 5,
    },
    card: {
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        padding: 5
    },
    name: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    text: {
        fontSize: 16
    }
})