import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

export default function Logo({ color }) {
    return (
        <View style={styles.container}>
            <Text style={styles.text}>Admin</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {   
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 5
    },
    right: {
        position: 'relative',
        marginLeft: -40,
        top: 20,
        transform: 'scaleX(-1)',
    },
    text: {
        fontFamily: 'Comic Sans MS',
        fontSize: 30,
        color: '#FF004C',
    }
})