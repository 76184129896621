import React, { useState, useEffect } from 'react';
import { View, Text, Button, ScrollView, Pressable, StyleSheet } from 'react-native';
import MyButton from './form/MyButton';
import NoRight from './NoRight';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function IdiomMenu({ navigation }) {
    const { user:admin } = useAuth();
    const roles = admin.roles;

    if (!roles.includes('Super User') && !roles.includes('Editor'))
        return <NoRight />;

    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Vocab/Idioms/');
        setData(data);
    }

    return (
        <View style={styles.container}>
            <ScrollView>
                <View style={styles.list}>
                    {data.map(item =>
                        <View key={item.cid}>
                            <Pressable onPress={() => navigation.navigate("IdiomForm", { ID: item.cid }) }>
                                <View style={styles.card}>
                                    <Text style={styles.text}>{item.chinese}</Text>
                                </View>
                            </Pressable>
                        </View>
                    )}
                    <MyButton text='新成语' color='dodgerblue' width={200} onPress={() => navigation.navigate("IdiomForm", { ID: 0 })} />
                </View>
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
    },
    list: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '90%',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        marginBottom: 30
    },
    card: {
        backgroundColor: 'white',
        padding: 5,
        borderWidth: 1,
        borderColor: 'lightgray',
    },
    text: {
        fontFamily: 'STZhongsong',
        fontSize: 30,
    },
})