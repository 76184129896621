import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Dashboard from '../components/Dashboard';

const Stack = createNativeStackNavigator();

const BoardNavigator = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name="Dashboard" component={ Dashboard } options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default BoardNavigator;