import React, { useState, useEffect } from 'react';
import { View, Text, Button, TextInput, StyleSheet } from 'react-native';
import MyButton from './form/MyButton';
import HTMLView from 'react-native-htmlview';
import UserGroup from './UserGroup';
import NoRight from './NoRight';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function Email() {
    const { user:admin } = useAuth();
    const roles = admin.roles;

    if (!roles.includes('Customer Service'))
        return <NoRight />;

    const [tempID, setTempID] = useState(1);    //template ID
    const [text, setText] = useState('');
    const [groupID, setGroupID] = useState(1);
    const [testMail, setTestMail] = useState('softnord9@gmail.com');

    const handleText = (text) => {
        setText(text);
    }

    const getTemplate = (id) => {
        setTempID(id);
        var client = new XMLHttpRequest();
        client.open('GET', '/email/template'+ id +'.txt');
        client.onreadystatechange = function() {
            setText(client.responseText);
        }
        client.send();
    }

    const save = async () => {
       let v = new FormData();
       v.append("ID", tempID);
       v.append("Text", text);
       await api.post('/Email/Template/', v);
    }

    const sendTest = async () => {
        api.put('/Email/Test/' + tempID + '/' + testMail);
    } 

    const send = () => {
        api.put('/Email/Group/' + tempID + '/' + groupID);
    }

    const forgotPass = async () => {
        await api.put('/Email/ForgotPass');
    }

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <Text>Hello</Text>
            </View>
            <View style={styles.right}>
                <View style={styles.box}>
                    <TextInput
                        editable={true}
                        multiline
                        numberOfLines={25}
                        value={text}
                        onChangeText={text => handleText(text)}
                        style={{padding: 5, borderWidth: 2, fontSize: 18, width: '100%', borderRadius: 2, borderColor: 'dodgerblue'}}
                    />
                    <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <MyButton text='Save Template' color='black' width={150} onPress={() => save()} />
                        <MyButton text='Send' color='limegreen' width={150} onPress={() => send()} />
                    </View>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EBF5FB',
    },
    menu: {
        flex: 1,
        flexDirection: 'column',
        marginBottom: 5
    },
    main: {
        flex: 8,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5
    },
    box: {
        width: '48%',
        justifyContent: 'flex-start',
        alignSelf: 'center',
    },
    menuBtn: {
        marginLeft: 10
    },
    english: {
        fontFamily: 'Candara',
        fontSize: 25,
        marginVertical: 10
    },
    title: {
        fontFamily: 'Candara',
        fontSize: 40,
    },
    a: {
        fontWeight: '300',
        color: '#FF3366', // make links coloured pink
    },
    text: {
        fontFamily: 'Calibri',
        fontSize: 18,
        fontWeight: 'bold'
    },
    textLine: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#D0ECE7',
        borderColor: 'black',
        borderBottomWidth: 2,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 15
    },
})