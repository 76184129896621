import React from 'react';
import { Text, Pressable, StyleSheet } from 'react-native';

export default function MyButton({ text, color, width, onPress }) {
    let w = width == undefined? {} : {width: width};
    let b = color == undefined? {} : {backgroundColor: color};
    return (
        <Pressable style={[styles.button, w, b]} onPress={onPress}>
            <Text style={styles.text}>{text}</Text>
        </Pressable>   
    );
}

const styles = StyleSheet.create({
    button: {
        width: '100%',
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
    },
    text: {
        fontFamily: 'Candara',
        color: 'white',
        fontSize: 20,
    },
})