import { useEffect } from 'react';
import useAuth from '../hooks/useAuth';

export default function Logout({ navigation }) {
    const { logOut } = useAuth();

    useEffect(() => {
        logOut();
        navigation.navigate("Login");
    }, []);

    return null;
}