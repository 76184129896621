import { createAppContainer } from 'react-navigation';
import { createSidebarNavigator } from '../tabs';

import BoardNavigator from './BoardNavigator';
import UserNavigator from './UserNavigator';
import ShopNavigator from './ShopNavigator';
import Ads from '../components/Ads';

import BookNavigator from './BookNavigator';
import IdiomNavigator from './IdiomNavigator';
import Newsletter from '../components/Newsletter';

import Email from '../components/Email';
import ChatManager from '../components/ChatManager';
import Logout from '../components/Logout';
import MemberOrder from '../components/MemberOrder';

const SidebarNavigator = createSidebarNavigator(
  {
    Dashboard: {
       screen: BoardNavigator,
        params: {
            icon: 'monitor-dashboard',
            tabName: 'Dashboard',
        },
    },
    Orders: {
      screen: MemberOrder,
      params: {
          icon: 'account',
          tabName: 'Orders',
      }
    },
    Users: {
      screen: UserNavigator,
      params: {
          icon: 'account',
          tabName: 'Users',
      }
    },
    Shop: {
      screen: ShopNavigator,
      params: {
          icon: 'shopping',
          tabName: 'Shop',
      }
    },
    Ads: {
      screen: Ads,
      params: {
          icon: 'go-kart',
          tabName: 'Ads',
      }
    },
    Book: {
      screen: BookNavigator,
        params: {
            icon: 'book',
            tabName: 'Book',
        }
    },
    Idiom: {
       screen: IdiomNavigator,
       params: {
           icon: 'file-word-box-outline',
           tabName: 'Idiom',
       },
    },
    Newsletter: {
      screen: Newsletter,
      params: {
          icon: 'email-edit-outline',
          tabName: 'Newsletter',
      }
    },
    Email: {
      screen: Email,
      params: {
          icon: 'email',
          tabName: 'Email',
      }
    },
    Chat: {
      screen: ChatManager,
      params: {
          icon: 'chat',
          tabName: 'Chat',
      }
    },
    Logout: {
      screen: Logout,
      params: {
          icon: 'logout',
          tabName: 'Logout',
      }
    },
  },
  {
    initialRouteName: 'Orders',
  },
);

export default createAppContainer(SidebarNavigator);