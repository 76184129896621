import React, { useState, useEffect } from 'react';
import { View, Text, Image, Modal, StyleSheet } from 'react-native';
import BuyButtons from './BuyButtons';
import Price from './Price';
import ProductOption from './ProductOption';
import ProductDesc from './ProductDesc';

import api from '../api/client';
import server from '../config/server';
import SkinCareSpec from './SkinCareDesc';

export default function Product({ navigation, route }) {

    const { productID } = route.params;
    const domain = server['domain'];

    const [product, setProduct] = useState(null);
    const [optionID, setOptionID] = useState(1);
    const [price, setPrice] = useState(1000);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (showModal)
            setTimeout(() => {
                setShowModal(false);
                }, 2000);
    }, [showModal]);

    const fetchData = async () => {
        const { data: product } = await api.get('/Product/' + productID);
        setProduct(product);
    }

    const setOption = (id, price) => {
        setOptionID(id);
        setPrice(price);
    }

    return (
        product &&
        <View style={styles.container}>
            <View style={styles.left}>
                <Image style={styles.image} source={{uri: domain + '/images/shop/'+ product.pid +'/1.jpg'}} />
            </View>
            <View style={styles.right}>
                <View style={{flexDirection: 'row'}}>
                    <Text style={[styles.name, {marginRight: 5}]}>{product.brand}</Text>
                    <Text style={styles.name}>{product.productName}</Text>
                </View>

                <Price price={price} size={25} />

                <ProductOption productID={product.pid} onSelect={(id, price) => setOption(id, price)} />

                <BuyButtons productID={product.pid} optionID={optionID} onAdd={() => setShowModal(true)} navigation={navigation} />

                {product.attriGroupID == 2? <SkinCareSpec productID={product.pid} />:
                <ProductDesc productID={product.pid} attriGroupID={product.attriGroupID} />}

                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={showModal}
                    onRequestClose={() => setVisible(!showModal)}
                >
                    <View style={styles.modal}>
                        <View style={{alignItems: 'center', justifyContent: 'center'}}>
                            <Text style={{fontSize: 20, fontFamily: 'Candara'}}>Product added to shopping cart</Text>
                        </View>
                    </View>
                </Modal>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    left: {
        flex: 2,   
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRightColor: 'black',
        borderRightWidth: 3,
        borderStyle: 'dotted',
        padding: 30
    },
    right: {
        flex: 3,
        flexDirection: 'column',
        padding: 30
    },
    image: {
        width: 410,
        height: 410,
    },
    name: {
        fontFamily: 'SegoeUIBlack',
        fontSize: 24,
        marginBottom: 20
    },

    description: {
        fontFamily: 'Candara',
        fontSize: 18
    },

    indShopping: {
        width: '50%',
        fontSize: 90
    },
    grpShopping: {
        width: '50%'
    },
    price: {
        fontFamily: 'Calibri',
        fontSize: 25,
        marginRight: 20
    },
    desc: {
        fontFamily: 'Candara',
        fontSize: 18
    },
    modal: {
        backgroundColor: '#ABEBC6',
        padding: 20,
    },
})
