import React from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import useAuth from '../hooks/useAuth';

const TopTabs = ({ navigation }) => {
  const { user } = useAuth();
  return (
    <>
        {user?
            <View style={styles.row}>

            </View>
            : null}
      </>
  );
};

export default TopTabs;

const styles = StyleSheet.create({
    row: {
      flex: 1,
      flexDirection: 'row'
    },
    button: {
      alignItems: 'center',
      justifyContent: 'center', 
      margin: 5,
      paddingVertical: 6
    },
    text: {
      fontFamily: 'Comic Sans MS',
      fontSize: 18,
      color: 'black',
    }
});