import React, { useState, useEffect } from 'react';
import { View, ScrollView, Pressable, Text, TextInput, StyleSheet } from 'react-native';
import Chat from './Chat';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function ChatForm({ chatID }) {

    console.log('chatID: ', chatID);

    const { user } = useAuth();

    const [data, setData] = useState([]);
    const [text, setText] = useState('');

    useEffect(() => {
        if (chatID > 0)
            setInterval(() => {
                fetchData();
            }, 5000);

            return () => {
              
            }
    }, [chatID]);

    const fetchData = async () => {
        console.log('fetchData');
        const { data: newData } = await api.get('/Chat/' + chatID);
        if (newData.length > data.length)
        setData(newData);
    }

    const sendMsg = async () => {
       // const myID = user.personID;
        const myID = 9;

        let s = encodeURIComponent(text.trim());
        await api.put("/Chat/" + chatID + '/' + myID + '/' + s);

        fetchData();
        setText('');
    }

    return (
        <View style={styles.container}>
            <View style={{flex: 9}}>
                <ScrollView>
                    <View style={styles.list}>
                        {data.map(item =>
                            <View key={item.id}>
                                <Chat data={item} />
                            </View>
                    )}
                    </View>
                </ScrollView>
            </View>
            <View style={styles.chatBox}>
                <TextInput style={styles.input} multiline value={text} onChangeText={text => setText(text)} autoCompleteType="off" />
                <Pressable style={styles.btn} onPress={() => sendMsg()}>
                    <Text style={{fontSize: 18, fontWeight: 'bold', color: 'white'}}>SEND</Text>
                </Pressable>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        backgroundColor: '#E8F6F3',
    },
    list: {
        flexWrap: 'wrap',
        flexDirection: 'column',
        width: '90%',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        marginBottom: 30
    },
    chatBox: {
        flex: 1,
        flexDirection: 'row',
    },
    btn: {
        width: 300,
        backgroundColor: '#82E0AA',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        borderBottomRightRadius: 15,
        height: 70
    },
    input: {
        flex: 1,
        backgroundColor: 'white',
        height: 70,
        borderBottomLeftRadius: 15,
        paddingVertical: 10,
        paddingHorizontal: 15,
        fontSize: 18,
    },
})