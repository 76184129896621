import React, { useState, useEffect } from 'react';
import { View, FlatList, StyleSheet } from 'react-native';
import api from '../api/client';

import Ad from './Ad';

export default function Ads() {
    const [data, setData] = useState([]);

    const fetchData = async () => {
        const { data } = await api.get('/Ads/' + 12);
        setData(data);
    }

    const renderItem = ({item}) => {
        const {aid, title, url} = item;
        return <Ad id={aid} title={title} url={url} />
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <View style={styles.container}>
            <View>
                <FlatList
                    data={data}
                    keyExtractor={item => item.aid}
                    renderItem={renderItem}
                    numColumns={6}
                ></FlatList>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#D4E6F1',
    },
    title: {
        fontSize: 20,
    },
    subtitle: {
        fontFamily: 'Comic Sans MS',
        fontSize: 15,
        marginBottom: 15
    },
})