import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import useAuth from '../hooks/useAuth';

import Shop from '../components/Shop';
import Login from '../components/Login';
import Product from '../components/Product';

const Stack = createNativeStackNavigator();

const ShopNavigator = () => {
    const { user } = useAuth();
    return (
        <Stack.Navigator>
            <Stack.Screen name="Shop" component={ Shop } options={{ headerShown: false }} />
            <Stack.Screen name="Product" component={ Product } options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default ShopNavigator;