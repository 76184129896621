import React from 'react';
import { Text, Pressable, StyleSheet } from 'react-native';
import { useFormikContext } from 'formik';

export default function SubmitButton({text, color, width}) {
    const { handleSubmit } = useFormikContext();

    const c = color == undefined? {backgroundColor:'#00BFFF'} : {backgroundColor : color};
    const w = width == undefined? {width:'100%'} : {width: width};

    return (
        <Pressable style={[styles.button, c, w]} onPress={handleSubmit}>
            <Text style={styles.text}>{text}</Text>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    button: {
        height: 50,
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
        alignItems: 'center',
        justifyContent: 'center'
    },
    text: {
        fontFamily: 'Candara',
        fontSize: 18,
        color: 'white'
    }
})