import React from 'react';
import { View, Button } from 'react-native';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function BuyButtons({ productID, optionID, onAdd, navigation }) {

    const { user } = useAuth();

    const addToCart = async () => {
        if (!user)
            return navigation.navigate('Login4', { prevScreen: 'Product', params: { productID: productID } });

        const { data: result } = await api.get('/Cart/Add/' + user.personID + '/' + productID + '/' + optionID);

        if (result == 1) {
            // const item = { id: product.pid, qty: 1 };
            // user.cart.push(item);
            onAdd();
        }
    }

    const handleCheckout = () => {
        if (!user)
            return navigation.navigate('Login4', { prevScreen: 'FastCheckout', params: { productID: productID, optionID: optionID } });
        
        return navigation.navigate("FastCheckout", { productID: productID, optionID: optionID });
    }

    return (    
        <View style={{flexDirection: 'row'}}>
            <View style={{flex: 1, marginRight: 10}}>
                <Button title="Add to Cart" color="#00BFFF" onPress={() => addToCart()} />
            </View>
            <View style={{flex: 1}}>
                <Button title="Buy Now" color="#000" onPress={() => handleCheckout()} />
            </View>
        </View>
    );
}