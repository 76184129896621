import React, { useState, useEffect } from 'react';
import { View, Text, Button, Image, Pressable, StyleSheet } from 'react-native';
import { FormLine, SubmitButton } from './form';
import { Formik } from 'formik';
import { FontAwesome5 } from '@expo/vector-icons';
import { launchImageLibrary } from 'react-native-image-picker';

import api from '../api/client';
import server from '../config/server';
import * as Yup from 'yup';

const schema = Yup.object().shape({
    chinese: Yup.string().label("Chinese Name").default(' '),
    english: Yup.string().label("English name").default(' '),
    author: Yup.string().label("Author").default(' '),
    translator: Yup.string().label("Translator").default(' ')
});

const options = {
    mediaType: 'photo',
    includeBase64: false,
    maxHeight: 2000,
    maxWidth: 2000,
};

export default function Book({ navigation, route }) {

    const domain = server['domain'];
    const { BID } = route.params;
    const btnName =  BID > 0? 'Update':'Create';

    const [uri, setUri] = useState(null);
    const [bookID, setBookID] = useState(BID);

    const [values, setValues] = useState({chinese: '', english: '', author: '', translator: ''});

    useEffect(() => {
        if (BID > 0)
            fetchData();
    }, []);
    
    const fetchData = async () => {
        const { data } = await api.get('/Book/' + BID);
        const { chinese, english, author, translator } = data;
        let values = { chinese, english, author, translator };
        setValues(values);
    }

    const openImagePicker = () => {
        launchImageLibrary(options, response => {
            if (response.didCancel) {
                console.log('User cancelled image picker');
            } else if (response.error) {
                console.log('Image picker error: ', response.error);
            } else {
                let imageUri = response.uri || response.assets?.[0]?.uri;
                setUri(imageUri);
                console.log('response.assets[0]', response.assets[0]);
                saveImage(response.assets[0]);
            }
        });
    }

    const saveImage = async (file) => {
        const body = new FormData();
        body.append('file', file);
        body.append('BID', bookID);

        console.log('file', file);

        if (!file.cancelled) {
            await api.post("/Book/Image", body);
            setUri(file.uri);
        }
    }

    const saveBook = async (values) => {
        const { chinese, english, author, translator } = values;

        let book = new FormData();
        book.append("BID", BID);
        book.append("Chinese", chinese);
        book.append("English", english);
        book.append("Author", author);
        book.append("Translator", translator);

        const { data } = await api.post("/Book/", book);
        setBookID(data);
    }

    const normalize = async () => {
        await api.put('/Book/Normalize/' + BID);
    }

    const makeAudio = async () => {
        const bookID = BID;
        await api.put('/Book/MP3/' + bookID);

    //    await api.put('/Book/MP3/TopicText/');
    //    await api.put('/Book/MP3/TopicDialog/');
    }

    const makePPT = async () => {
        await api.put('/Book/PPT/' + BID);
    }

    const handlePress = () => {
        navigation.navigate("BookText", { BID: BID });
    }

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <Pressable onPress={() => handlePress()}>
                    <View style={styles.card}>
                        {/* <Pressable onPress={() => openImagePicker()}> */}
                            {BID > 0 ? <Image style={styles.image} source={{uri: domain + '/images/book/' + BID + '.jpg'}} /> :
                                    <View style={{alignItems: 'center'}}>
                                        <FontAwesome5 name='photo-video' color='black' size={50} />
                                        <Text style={{marginTop: 30}}>CHOOSE PHOTO FROM DEVICE</Text>
                                    </View>
                            }
                        {/* </Pressable> */}
                    </View>
                </Pressable>
            </View>
            <View style={styles.right}>
                <Text>Book ID: {bookID}</Text>
                <View style={styles.form}>
                    <Formik
                        enableReinitialize
                        initialValues={values}
                        onSubmit={(values, actions) => { saveBook(values) }}
                        validationSchema={schema}
                    >
                        {({ handleChange, values }) => (
                    <>
                        <FormLine 
                            name="chinese"
                            placeholder='Chinese Title'
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="default"
                            textContentType="default"
                            value={values.chinese}
                            onChangeText={handleChange('chinese')}
                        />
                        <FormLine 
                            name="english"
                            placeholder='English Title'
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="default"
                            textContentType="default"
                            value={values.english}
                            onChangeText={handleChange('english')}
                        />
                        <FormLine 
                            name="author"
                            placeholder='Author'
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="default"
                            textContentType="default"
                            value={values.author}
                            onChangeText={handleChange('author')}
                        />
                        <FormLine 
                            name="translator"
                            placeholder='Translator'
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="default"
                            textContentType="default"
                            value={values.translator}
                            onChangeText={handleChange('translator')}
                        />
                        <SubmitButton text={btnName} color="dodgerblue"/>
                        </>
                        )}
                    </Formik>
                </View>

                <View style={styles.btnV}>
                    <View style={{flex: 1}}>
                        <Button title="Normalize" onPress={() => normalize()} color="#841584" />
                    </View>
                    <View style={{flex: 1}}>
                        <Button title="MP3" onPress={() => makeAudio()} color="dodgerblue" />
                    </View>
                    <View style={{flex: 1}}>
                        <Button title="PPT" onPress={() => makePPT()} color="orange" />
                    </View>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#EBF5FB',
    },
    left: {
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    right: {
        width: '50%',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: 30
    },
    form: {
        width: '80%'
    },
    btnV: {
        width: '80%',
        flexDirection: 'row',
        marginTop: 30
    },
    card: {
        backgroundColor: 'white',
        width: 320,
        height: 320,
        margin: 20,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        boxShadow: '0 10px 20px rgb(0 0 0 / 0.3)',
    },
    image: {
        width: 300,
        height: 300,
        resizeMode: 'contain',
    },
    bookTitle: {
        fontFamily: 'STZhongsong',
        fontSize: 50,
        marginBottom: 30
    },
    author: {
        fontFamily: 'STZhongsong',
        fontSize: 30,
        marginBottom: 30
    },
    english: {
        fontFamily: 'STZhongsong',
        fontSize: 40,
        marginBottom: 30
    },
    row: {
        flexDirection: 'row',
    },
    translator: {
        fontFamily: 'STZhongsong',
        fontSize: 30,
    },
    text: {
        fontFamily: 'Microsoft Black',
        fontSize: 25,
        marginRight: 30
    },
})