import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Pressable, StyleSheet, ScrollView } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import MyButton from './form/MyButton';
import Template from './Template';
import UserGroup from './UserGroup';
import NoRight from './NoRight';
import HTMLView from 'react-native-htmlview';
import UserList from './UserList';
import Feedback from './Feedback';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function Newsletter() {
    const { user:admin } = useAuth();
    const roles = admin.roles;

    if (!roles.includes('Editor'))
        return <NoRight />;

    const [tempID, setTempID] = useState(1);    //template ID
    const [text, setText] = useState('');
    const [groupID, setGroupID] = useState(0);
    const [selected, setSelected] = useState([]);  //selected users

    const [subject, setSubject] = useState('');
    const [showHTML, setShowHTML] = useState(true);

    const [msg, setMsg] = useState('');
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        getSubject();
        getTemplate();
    }, [tempID]);

    useEffect(() => {
        msg.length > 0? setVisible(true) : setVisible(false);
    }, [msg]);

    const getTemplate = () => {
        let file = '/email/template'+ tempID +'.txt';
        let client = new XMLHttpRequest();
        client.open('GET', file);
        client.onreadystatechange = function() {
            setText(client.responseText);
        }
        client.send();
    }

    const getSubject = async () => {
        const { data } = await api.get('/Email/Subject/' + tempID);
        setSubject(data);
    }

    const saveSubject = async () => {
        let s = encodeURIComponent(subject.trim());
        await api.put('/Email/Subject/' + tempID + '/' + s);
    }

    const saveTemplate = async () => {
        let v = new FormData();
        v.append("ID", tempID);
        v.append("TempName", "N");
        v.append("Text", text);

        const { data } = await api.post('/Email/Template/', v);
        if (data == 1)
            setMsg('Saved');
    }

    const sendGroup = async () => {
        const { data: failed } = await api.put('/Email/Group/' + tempID + '/' + groupID);
  
        if (failed.length == 0)
            setMsg('Group mail sent successfully!');
        else {
            setSelected(failed);
            setShowHTML(false);
        }
    }

    const send = async (email, name) => {
        await api.put('/Email/' + tempID + '/' + email + '/' + name);
    }

    return (
        <View style={styles.container}>
            <View style={styles.menu}>
                <UserGroup groupID={groupID} onSelect={g => {setGroupID(g); setShowHTML(false);}} />
                <Template tempID={tempID} onSelect={t => setTempID(t)} />
                <View style={{flex: 1, flexDirection: 'row'}}>
                    <View style={{width: 100}}>
                        <Text style={{fontFamily: 'Calibri', fontSize: 18}}>Subject</Text>
                    </View>
                    <View style={styles.textLine}>
                        <TextInput style={{width:'100%', height:'100%', fontSize: 18}}
                            value={subject}
                            editable={true}
                            onChangeText={t => setSubject(t)}
                            autoCompleteType="off"
                        />
                    </View>
                    <Pressable style={styles.saveBtn} onPress={() => saveSubject()}>
                        <MaterialCommunityIcons name="content-save" color="black" size={30} />
                    </Pressable>
                </View>
            </View>
            <View style={styles.main}>
                <View style={styles.box}>
                    <TextInput
                        value={text}
                        editable={true}
                        multiline
                        numberOfLines={25}
                        onChangeText={t => setText(t)}
                        onFocus={() => setShowHTML(true)}
                        style={{padding: 5, borderWidth: 2, fontSize: 18, width: '100%', borderRadius: 2, borderColor: 'dodgerblue'}}
                    />
                </View>
                <View style={[styles.box, {backgroundColor: 'white'}]}>
                    <ScrollView>
                    {showHTML ? <HTMLView value={text} stylesheet={styles} /> : 
                                <UserList groupID={groupID} selected={selected} onSelect={(email, name) => send(email, name)} />}
                    </ScrollView>
                </View>
            </View>
            <View style={styles.footer}>
                <View style={styles.btns}>
                    <MyButton text='Save Template' color='black' width={150} onPress={() => saveTemplate()} />
                </View>
                <View style={styles.btns}>
                    <MyButton text='Send Group' color='limegreen' width={150} onPress={() => sendGroup()} />
                </View>
            </View>
            {visible && <Feedback text={msg} visible={visible} />}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#EBF5FB',
        padding: 10
    },
    menu: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: 'auto',
        marginBottom: 15
    },
    main: {
        height: 'auto',
        minHeight: '70%',
        alignItems: 'center',
        flexDirection: 'row',
        paddingHorizontal: 5
    },
    footer: {
        height: 80,
        flexDirection: 'row'
    },
    btns: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%'
    },
    box: {
        width: '48%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        alignSelf: 'center',
    },
    menuBtn: {
        marginLeft: 10
    },
    english: {
        fontFamily: 'Candara',
        fontSize: 25,
        marginVertical: 10
    },
    title: {
        fontFamily: 'Candara',
        fontSize: 40,
    },
    text: {
        fontFamily: 'Calibri',
        fontSize: 18,
        fontWeight: 'bold'
    },
    textLine: {
        width: 600,
        flexDirection: 'row',
        backgroundColor: '#D0ECE7',
        borderColor: 'black',
        borderBottomWidth: 2,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 15,
        height: 40
    },
    saveBtn: {
        backgroundColor: '#A3E4D7',
        width: 40,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        marginLeft: 10
    },
    body: {
        fontSize: 18
    },
    a: {
        fontWeight: '300',
        color: '#FF3366', // make links coloured pink
    },
})