import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import NoRight from './NoRight';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function Dashboard() {

    const { user:admin } = useAuth();
    const roles = admin.roles;

    if (!roles.includes('Super User') && !roles.includes('Accountant'))
        return <NoRight />;

    const [yearRevenue, setYearRevenue] = useState(0);
    const [monthRevenue, setMonthRevenue] = useState(0);
    const [userCount, setUserCount] = useState(0);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Board/');
        setYearRevenue(data.item1);
        setMonthRevenue(data.item2);
        setUserCount(data.item3);
    }

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <View style={[styles.card, {backgroundColor: '#D1F2EB'}]}>
                    <Text style={styles.title}>Revenue this year:</Text>
                    <Text style={styles.data}>$ {yearRevenue}</Text>
                </View>
                <View style={[styles.card, {backgroundColor: '#D1F2EB'}]}>
                    <Text style={styles.title}>Revenue this month:</Text>
                    <Text style={styles.data}>$ {monthRevenue}</Text>
                </View>
            </View>
            <View style={styles.row}>
                <View style={[styles.card, {backgroundColor: '#E8DAEF'}]}>
                    <Text style={styles.title}>Users:</Text>
                    <Text style={styles.data}>{userCount}</Text>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#EBF5FB',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    card: {
        width: '20vw',
        height: '20vh',
        padding: 30,
        margin: 30,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 5px 15px rgb(0 0 0 / 0.5)',
    },
    title: {
        fontFamily: 'Candara',
        fontSize: 22,
        marginBottom: 20
    },
    data: {
        fontFamily: 'STZhongsong',
        fontSize: 30
    },
})