import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import BookMenu from '../components/BookMenu';
import Book from '../components/Book';
import BookText from '../components/BookText';

const Stack = createNativeStackNavigator();

const BookNavigator = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name="BookMenu" component={ BookMenu } options={{ headerShown: false }} />
            <Stack.Screen name="Book" component={ Book } options={{ headerShown: false }} />
            <Stack.Screen name="BookText" component={ BookText } options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default BookNavigator;