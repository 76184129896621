import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

export default function Price({ price, size, color }) {
    const f = size != undefined? {fontSize: size} : null;
    const fs = size != undefined? {fontSize: size - 5} : null;
    const c = color != undefined? {color: color} : null;

    const decimal = (d) => {
        let dec = '.00';
        let s = '' + d;
        let index = s.indexOf('.');
        if (index > 0) {
            dec = s.substring(index);
            if (dec.length < 3)
                dec += '0';
        }
        return dec;
    }

    return (
        <View style={styles.container}>
            <Text style={[styles.price, f, c]}>$ {Math.floor(price)}</Text>
            <Text style={[styles.decimal, fs, c]}>{decimal(price)}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'baseline'
    },
    price: {
        fontFamily: 'Verdana',
        fontSize: 20,
        color: 'black'
    },
    decimal: {
        fontFamily: 'Verdana',
        fontSize: 15,
        color: 'black',
    },
})