import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import MyButton from './form/MyButton';
import api from '../api/client';

export default function RolePanel({ userID, adminID }) {

    const [roles, setRoles] = useState([]);
    const color = "#AED6F1";
    const activeColor = "#FF004C";

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/User/Role/' + userID);
        setRoles(data);
    }

    const addRole = async (roleID) => {
        await api.put('/User/Role/' + userID + '/' + roleID + '/' + adminID);
        fetchData();
    }

    return (
        <View style={styles.container}>
            <View style={styles.list}>
                <MyButton text="Super User" color={roles.includes("Super User")? activeColor : color} width={300} onPress={() => addRole(1)} />
                <MyButton text="Accountant" color={roles.includes("Accountant")? activeColor : color} width={300} onPress={() => addRole(2)} />
                <MyButton text="Editor" color={roles.includes("Editor")? activeColor : color} width={300} onPress={() => addRole(3)} />
                <MyButton text="Ecommerce Manager" color={roles.includes("Ecommerce Manager")? activeColor : color} width={300} onPress={() => addRole(4)} />
                <MyButton text="Customer Service" color={roles.includes("Customer Service")? activeColor : color} width={300} onPress={() => addRole(5)} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '40%',
        height: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 30
    },
    list: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '90%',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        marginBottom: 30,
        padding: 30
    },
})